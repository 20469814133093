// extracted by mini-css-extract-plugin
export var accordianHeader = "Faq-module--accordianHeader--70b9a";
export var accordianItem = "Faq-module--accordianItem--ed4cb";
export var accordianText = "Faq-module--accordianText--37aa1";
export var contactUnschool = "Faq-module--contactUnschool--1b906";
export var courseFaqContainer = "Faq-module--courseFaqContainer--ce8d3";
export var courseFaqContent = "Faq-module--courseFaqContent--458e0";
export var courseFaqContentLeft = "Faq-module--courseFaqContentLeft--779e1";
export var courseFaqContentRight = "Faq-module--courseFaqContentRight--e4e30";
export var faqImageContainer = "Faq-module--faqImageContainer--2211b";
export var headingContainer = "Faq-module--headingContainer--8af23";
export var headingDesktop = "Faq-module--headingDesktop--79a65";
export var headingMobile = "Faq-module--headingMobile--df044";
export var iconClassName = "Faq-module--iconClassName--9362c";
export var lmsScrollbar = "Faq-module--lms-scrollbar--93610";