import React, { useCallback, useEffect, useState } from "react";
import { Col } from "antd";
import { useLazyQuery } from "@apollo/client";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";

import * as classes from "./SearchCourses.module.scss";
import { GET_COURSES } from "../../../../graphql/queries/courses";
import { IMAGE_PLACEHOLDER } from "../../../utils/localization";
import { INTERNSHIP_PROGRAM } from "../../../utils/localization";

const SearchCourses = () => {
  const [courseResultsList, setCourseResultsList] = useState([]);
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [showCourseResultList, setShowCourseResultList] = useState(false);

  const [onSearch] = useLazyQuery(GET_COURSES, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setShowCourseResultList(true);
      setCourseResultsList(data.courses.data);
    },
  });

  const handleCourseSearch = (value) => {
    setSearchKeyWord(value);
  };

  const handleCloseSearch = useCallback(() => {
    setSearchKeyWord("");
    setShowCourseResultList(false);
  }, []);

  useEffect(() => {
    if (searchKeyWord.length >= 3) {
      const searchTimer = setTimeout(() => {
        onSearch({
          variables: {
            input: {
              q: searchKeyWord,
              productLine: "internship_program",
            },
          },
        });
      }, 1000);

      return () => {
        clearTimeout(searchTimer);
      };
    } else {
      setShowCourseResultList(false);
    }
  }, [searchKeyWord]);

  const getCourseOutLink = (course) => {
    switch (course.productLine) {
      case INTERNSHIP_PROGRAM:
        return `/internship-program/${course.slug}`;
      default:
        return `/skill-courses/${course.slug}`;
    }
  };

  return (
    <Col className={classes.searchCourses}>
      <Col className={classes.searchCoursesInputContainer}>
        <input
          placeholder="Search Courses"
          value={searchKeyWord}
          onChange={(e) => {
            handleCourseSearch(e.target.value);
          }}
        />
        <Col>
          {showCourseResultList ? (
            <CloseOutlined
              onClick={handleCloseSearch}
              className={classes.searchIcon}
            />
          ) : (
            <SearchOutlined className={classes.searchIcon} />
          )}
        </Col>
      </Col>
      {showCourseResultList && (
        <Col className={classes.courseResultListContainer}>
          <p className={classes.searchResultLable}>
            Search for <b>"{searchKeyWord}"</b>
          </p>
          <Col>
            {courseResultsList.map((course) => (
              <Col key={course.id} className={classes.courseResultListitem}>
                <a href={getCourseOutLink(course)} target="_blank">
                  <Col className={classes.courseImageContainer}>
                    <img
                      src={course?.coverImage?.url || IMAGE_PLACEHOLDER}
                      alt={course.title}
                      className={classes.courseImage}
                    />
                  </Col>
                  <p className={classes.courseTitle}>{course.title}</p>
                </a>
              </Col>
            ))}
          </Col>
        </Col>
      )}
    </Col>
  );
};

export default SearchCourses;
