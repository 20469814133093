import React from "react";
import "react-medium-image-zoom/dist/styles.css";

import Courses from "../../components/InternshipProgramLanding1.0/Courses";
import Unschooler from "../../components/InternshipProgramLanding1.0/Unschooler";
import Hero from "../../components/InternshipProgramLanding1.0/Hero";
import LayoutWithOutPadding from "../../components/LayoutWithOutPadding";
import InternshipOpportunities from "../../components/InternshipProgramLanding1.0/InternshipOpportunities";
import LearningAt from "../../components/InternshipProgramLanding1.0/LearningAt";
import Stats from "../../components/InternshipProgramLanding1.0/Stats";
import Coaches from "../../components/InternshipProgramLanding1.0/Coaches";
import Awards from "../../components/InternshipProgramLanding1.0/Awards";
import Faq from "../../components/InternshipProgramLanding1.0/Faq";
import CourseFooterCategories from "../../components/InternshipProgramLanding1.0/CourseFooterCategories";
import Seo from "../../components/InternshipProgramLanding1.0/Seo";
import { useCategoryCourseList } from "../../components/InternshipProgramLanding1.0/hooks/useCategoryCourseList";
import NSDC from "../../components/InternshipProgramLanding1.0/NSDC";

const IntershipProgram = () => {
  const { categoryCourseList } = useCategoryCourseList();

  return (
    <LayoutWithOutPadding>
      <Hero />
      <Unschooler />
      <NSDC />
      <Courses categoryCourseList={categoryCourseList} />
      <InternshipOpportunities />
      <LearningAt />
      <Stats />
      <Coaches />
      <Awards />
      <Faq />
      <CourseFooterCategories categoryCourseList={categoryCourseList} />
    </LayoutWithOutPadding>
  );
};

export function Head() {
  return <Seo title={"Internship Program | Unschool"} />;
}

export default IntershipProgram;
