import { Col, Typography } from "antd";
import React from "react";

import * as classes from "./Unschooler.module.scss";
import { INTERNSHIP_PROGRAM_PAGE_FILES_PATH } from "../../../utils/localization";
import { useState } from "react";

const { Title } = Typography;

const Unschooler = () => {
  const [imageUrl, setImageUrl] = useState(
    `${INTERNSHIP_PROGRAM_PAGE_FILES_PATH}/oppotunity.png`
  );

  return (
    <Col className={classes.unschoolerContainer}>
      <Col className={classes.wrapper}>
        <Title className={classes.title}>
          Perks of being an <span>Unschooler</span>
        </Title>
        <Col className={classes.content}>
          <div className={classes.imageContainer}>
            <div
              onMouseMoveCapture={() => {
                setImageUrl(
                  `${INTERNSHIP_PROGRAM_PAGE_FILES_PATH}/oppotunity-1.png`
                );
              }}
            />
            <div
              onMouseMoveCapture={() => {
                setImageUrl(
                  `${INTERNSHIP_PROGRAM_PAGE_FILES_PATH}/expert-1.png`
                );
              }}
            />
            <div
              onMouseMoveCapture={() => {
                setImageUrl(
                  `${INTERNSHIP_PROGRAM_PAGE_FILES_PATH}/community-1.png`
                );
              }}
            />
            <div
              onMouseMoveCapture={() => {
                setImageUrl(
                  `${INTERNSHIP_PROGRAM_PAGE_FILES_PATH}/certification-1.png`
                );
              }}
            />
            <img
              className={classes.heroRightImage}
              src={`${INTERNSHIP_PROGRAM_PAGE_FILES_PATH}/unschoolar-time-liner-2.svg`}
            />
            <img src={imageUrl} className={classes.heroLeftImage} />
          </div>
        </Col>
      </Col>
    </Col>
  );
};

export default Unschooler;
