import React from "react";
import { Col, Typography } from "antd";
import { CustomButton } from "../../adaptors/CustomButton";

import * as classes from "./InternshipOpportunities.module.scss";
import { variants } from "../../../utils/variants";
import { INTERNSHIP_PROGRAM_PAGE_FILES_PATH } from "../../../utils/localization";
import { INTERNSHIPS_URL } from "../../../utils/localization";

const { Title } = Typography;

const InternshipOpportunities = () => {
  return (
    <Col className={classes.container}>
      <Col className={classes.wrapper}>
        <Col className={classes.left}>
          <Title>Assured internship opportunities</Title>
          <p>
            We ensure that you take the first step into the industry with a
            reputed firm. From there, you’re equipped to fly.
          </p>
          <Col className={classes.buttonWrapper}>
            <CustomButton
              title={"Explore Internships"}
              variant={variants.primaryButton}
              customClass={classes.customButtonClass}
              customTextClass={classes.customTextClass}
              onClick={() => {
                window.open(INTERNSHIPS_URL);
              }}
            />
          </Col>
        </Col>
        <Col className={classes.right}>
          <img
            src={`${INTERNSHIP_PROGRAM_PAGE_FILES_PATH}/internship-opportunities-hero.png`}
            alt="hero"
          />
        </Col>
      </Col>
    </Col>
  );
};

export default InternshipOpportunities;
