import React from "react";
import { Col, Typography } from "antd";

import * as classes from "./Hero.module.scss";
import ProgramForm from "./ProgramForm";
import { INTERNSHIP_PROGRAM_PAGE_FILES_PATH } from "../../../utils/localization";

const { Title, Paragraph } = Typography;

const Hero = () => {
  return (
    <Col className={classes.heroContainer}>
      <Col className={classes.heroWrapper}>
        <Col className={classes.content}>
          <Col className={classes.left}>
            <Col>
              <Title>Getting You</Title>
              <Title>Corporate-Ready</Title>
              <Title className={classes.headerMobile}>
                Getting You Corporate-Ready
              </Title>
              <Paragraph>
                Intern with a well-established corporate space that will add
                value to your resume and can help you get placed with bigger
                opportunities.
              </Paragraph>
              <Col className={classes.achievements}>
                <Col>
                  <p>Approved Training Partner</p>
                  <img src={`${INTERNSHIP_PROGRAM_PAGE_FILES_PATH}/nsdc.svg`} />
                </Col>
                <Col>
                  <p>LinkedIn Top Startups 2020</p>
                  <img
                    src={`${INTERNSHIP_PROGRAM_PAGE_FILES_PATH}/linkedIn-top-startup.svg`}
                  />
                </Col>
              </Col>
            </Col>
          </Col>
          <Col className={classes.right}>
            <ProgramForm />
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

export default Hero;
