import { useStaticQuery, graphql } from "gatsby";

export const useCategoryCourseList = () => {
  const { api } = useStaticQuery(
    graphql`
      query queryCategoriesInternship {
        api {
          categoryCourseList {
            data {
              courses {
                nodes {
                  title
                  id
                  slug
                  productLine
                  visibleOnHomePage
                  visibleOnProductPage
                  coverImage {
                    url
                  }
                  coaches {
                    firstName
                    lastName
                    profilePicture {
                      url
                    }
                    userType
                  }
                }
              }
              name
              id
              slug
              profilePicture {
                url
                id
              }
            }
          }
        }
      }
    `
  );

  return api;
};
