import React from "react";
import { Col } from "antd";
import PropTypes from "prop-types";

import * as classes from "./Categories.module.scss";
import classNames from "classnames";

const Categories = (props) => {
  const { categories, selectedCategory, setSelectedCategory } = props;

  const handleClick = (slug) => {
    if (slug === "all-categories") {
      const element = document.getElementById("courseCategories");
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    } else {
      setSelectedCategory(slug);
    }
  };

  return (
    <Col className={classes.categories}>
      <h1>Categories</h1>
      {categories.map((category, index) => (
        <h3
          className={classNames(
            classes.categoryName,
            category.slug === selectedCategory && classes.active
          )}
          onClick={() => {
            handleClick(category.slug);
          }}
          key={category.slug}
        >
          {category.title}
        </h3>
      ))}
    </Col>
  );
};

Categories.propTypes = {
  categories: PropTypes.array,
  selectedCategory: PropTypes.object,
  setSelectedCategory: PropTypes.func,
};

export default Categories;
