// extracted by mini-css-extract-plugin
export var active = "Course-module--active--cb282";
export var categories = "Course-module--categories--bf577";
export var categoryName = "Course-module--categoryName--d7296";
export var container = "Course-module--container--d464d";
export var controlButtons = "Course-module--controlButtons--abcdd";
export var courseCarousel = "Course-module--courseCarousel--663b6";
export var coursesCarouselDesktop = "Course-module--coursesCarouselDesktop--c78bc";
export var coursesCarouselMobile = "Course-module--coursesCarouselMobile--16f49";
export var coursesContentDesktop = "Course-module--coursesContentDesktop--c48dc";
export var coursesContentMobile = "Course-module--coursesContentMobile--016cf";
export var lmsScrollbar = "Course-module--lms-scrollbar--757f2";
export var searchContainer = "Course-module--searchContainer--01abc";
export var title = "Course-module--title--c41dd";
export var wrapper = "Course-module--wrapper--02c7e";