import { INTERNSHIP_PROGRAM_PAGE_FILES_PATH } from "../../../utils/localization";

export const categories = {
  all_categories: {
    title: "All Categories",
    value: "All Categories",
    slug: "all-categories",
    id: 1,
  },
  design: {
    title: "Design",
    value: "Design",
    slug: "design",
    id: 2,
  },
  marketing: {
    title: "Marketing",
    value: "Marketing",
    slug: "marketing",
    id: 3,
  },
  "coding-and-development": {
    title: "Coding",
    value: "Coding",
    slug: "coding-and-development",
    id: 4,
  },
  "business-and-entrepreneurship": {
    title: "Business",
    value: "Business",
    slug: "business-and-entrepreneurship",
    id: 5,
  },
  "software-and-technology": {
    title: "Software",
    value: "Software",
    slug: "software-and-technology",
    id: 6,
  },
  vacational: {
    title: "Vocational",
    value: "Vocational",
    slug: "vocational",
    id: 7,
  },
  "finance-and-accounting": {
    title: "Finance",
    value: "Finance",
    slug: "finance-and-accounting",
    id: 8,
  },
};

export const coaches = [
  {
    name: "Harish Balaji",
    course: "Advanced Python",
    imageUrl: `${INTERNSHIP_PROGRAM_PAGE_FILES_PATH}/harish.jpeg`,
    companyLogo: `${INTERNSHIP_PROGRAM_PAGE_FILES_PATH}/morgan-stanley.png`,
    id: 1,
  },
  {
    name: "Zahin Hussain",
    course: "Product Development",
    imageUrl: `${INTERNSHIP_PROGRAM_PAGE_FILES_PATH}/zahin.png`,
    companyLogo: `${INTERNSHIP_PROGRAM_PAGE_FILES_PATH}/ankur-capital.png`,
    id: 2,
  },
  {
    name: "Himanshu Raghani",
    course: "Stock Market & Personal Finance",
    imageUrl: `${INTERNSHIP_PROGRAM_PAGE_FILES_PATH}/himanshu.jpeg`,
    companyLogo: `${INTERNSHIP_PROGRAM_PAGE_FILES_PATH}/kotak-securities-logo.png`,
    id: 3,
  },
  {
    name: "Anisha Sharma",
    course: "App Development using Android Studio",
    imageUrl: `${INTERNSHIP_PROGRAM_PAGE_FILES_PATH}/anisha.jpeg`,
    companyLogo: `${INTERNSHIP_PROGRAM_PAGE_FILES_PATH}/swiss-himmel.png`,
    id: 4,
  },
  {
    name: "Alex Badita",
    course: "Art of Journaling",
    imageUrl: `${INTERNSHIP_PROGRAM_PAGE_FILES_PATH}/alex badita.png`,
    companyLogo: `${INTERNSHIP_PROGRAM_PAGE_FILES_PATH}/oriflame.png`,
    id: 5,
  },
];

export const categoriesFooter = {
  design: {
    title: "Design",
    value: "Design",
    slug: "design",
    id: 2,
  },
  marketing: {
    title: "Marketing",
    value: "Marketing",
    slug: "marketing",
    id: 3,
  },
  "coding-and-development": {
    title: "Coding",
    value: "Coding",
    slug: "coding-and-development",
    id: 4,
  },
  "business-and-entrepreneurship": {
    title: "Business",
    value: "Business",
    slug: "business-and-entrepreneurship",
    id: 5,
  },
  "software-and-technology": {
    title: "Software",
    value: "Software",
    slug: "software-and-technology",
    id: 6,
  },
  vacational: {
    title: "Vocational",
    value: "Vocational",
    slug: "vocational",
    id: 7,
  },
  "finance-and-accounting": {
    title: "Finance",
    value: "Finance",
    slug: "finance-and-accounting",
    id: 8,
  },
};

export const stats = [
  { value: "200k+", title: "Learners", id: 1 },
  { value: "500K+", title: "Enrollments", id: 2 },
  { value: "100%", title: "Placements", id: 3 },
  { value: "150+", title: "Industry experts", id: 4 },
  { value: "110+", title: "Hiring Partners", id: 5 },
];

export const faqs = [
  {
    question: "I am confused about the courses, who do I contact?",
    answer:
      "Contact our career counsellor at +91 9154254541, and they will assist you in understanding your interest and career potential.",
  },
  {
    question: "Is there a demo lecture available?",
    answer:
      "Yes, as you sign-up, you will be given access to watch one whole chapter for any course as a free-demo video.",
  },
  {
    question: "How long is the course duration?",
    answer:
      "Course duration varies based on our programs. From self-paced learning to live lectures and one to one mentorship, we have got you covered.",
  },
  {
    question: "How can I get the NSDC certificate?",
    answer:
      "We have over 30 courses/programs that are NSDC approved. You will receive the NSDC certificate via email once you finish the course/programs successfully. This is only valid if you have pre-purchased the NSDC add-on while buying the course/program.",
  },
  {
    question: "How do I cancel my purchase?",
    answer:
      "If you don’t want to continue, you can cancel your purchase through your account page. Follow the prompts to cancel on the settings page. Please note that there aren’t refunds for parrtially unused membership periods.",
  },
];

export const awards = [
  {
    logo: `${INTERNSHIP_PROGRAM_PAGE_FILES_PATH}/village-capital.svg`,
    title: "Winners of the Future of Work India 2021 Cohort",
    id: 1,
  },
  {
    logo: `${INTERNSHIP_PROGRAM_PAGE_FILES_PATH}/start-up.svg`,
    title: "Recognised by Telangana Government",
    id: 2,
  },
  {
    logo: `${INTERNSHIP_PROGRAM_PAGE_FILES_PATH}/nasscom.svg`,
    title: "Official Internship Partners with Nasscom",
    id: 3,
  },
  {
    logo: `${INTERNSHIP_PROGRAM_PAGE_FILES_PATH}/task.svg`,
    title: "Official Training Partners",
    id: 4,
  },
  {
    logo: `${INTERNSHIP_PROGRAM_PAGE_FILES_PATH}/aws-edStart.svg`,
    title: "AWS Edstart Member",
    id: 5,
  },
  {
    logo: `${INTERNSHIP_PROGRAM_PAGE_FILES_PATH}/skill-Ap.svg`,
    title: "Official Training Partners",
    id: 6,
  },
  {
    logo: `${INTERNSHIP_PROGRAM_PAGE_FILES_PATH}/nsdc-logo.png`,
    title: "Approved Training Partner",
    id: 6,
  },
];
