import React, { useRef, useState, useEffect } from "react";
import { Col } from "antd";
import PropTypes from "prop-types";
import Slider from "react-slick";

import * as classes from "./Course.module.scss";
import CourseCard from "../CourseCard";
import { useMemo } from "react";
import { ReactSlickController } from "../../adaptors/ReactSlickController";
import { isSSR } from "../../../helpers/global";

const CoursesCarouselDesktop = (props) => {
  const { courseList } = props;

  const sliderRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const [buttonsController, setButtonController] = useState({
    next: () => {},
    prev: () => {},
    slickGoTo: () => {},
  });

  const screenWidth = useMemo(() => {
    if (isSSR()) {
      return 0;
    } else {
      return window?.innerWidth;
    }
  }, []);

  const calculateNoOfSildesToShow = useMemo(() => {
    if (screenWidth <= 1300) {
      return 2;
    }
    return 3;
  }, []);

  const checkInfineteSlideIsRequired = () => {
    if (screenWidth <= 1300) {
      if (courseList.length <= 2) {
        return false;
      }
      return true;
    } else {
      if (courseList.length <= 3) {
        return false;
      }
      return true;
    }
  };

  const settings = {
    dots: false,
    arrows: false,
    centerMode: false,
    slidesToShow: 3,
    speed: 500,
    rows: 1,
    infinite: checkInfineteSlideIsRequired(),
    beforeChange: (current, next) => {
      setActiveSlide(next);
    },
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  useEffect(() => {
    const slickController = { ...sliderRef?.current };
    setButtonController((state) => ({
      ...state,
      next: slickController?.slickNext,
      prev: slickController?.slickPrev,
      slickGoTo: slickController?.slickGoTo,
    }));
    setActiveSlide(0);
  }, [sliderRef, courseList, courseList.length]);

  return (
    <Col className={classes.coursesCarouselDesktop}>
      <Col>
        <Slider {...settings} ref={sliderRef}>
          {courseList.map((course) => (
            <CourseCard key={course.id} course={course} />
          ))}
        </Slider>
      </Col>
      <Col className={classes.controlButtons}>
        <ReactSlickController
          next={buttonsController.next}
          prev={buttonsController.prev}
          slickGoTo={buttonsController.slickGoTo}
          activeSlide={activeSlide}
          totalSlides={courseList.length}
          slidesToShow={calculateNoOfSildesToShow}
        />
      </Col>
    </Col>
  );
};

CoursesCarouselDesktop.propTypes = {
  courseList: PropTypes.array,
};

export default CoursesCarouselDesktop;
