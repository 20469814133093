import React from "react";
import { Col, Typography } from "antd";

import * as classes from "./Awards.module.scss";
import { flatten } from "lodash";
import { awards } from "../Utils/data";

const Marquee =
  typeof window !== `undefined`
    ? require("react-simple-marquee").default
    : null;

const { Title } = Typography;

const Awards = () => {
  return (
    <Col className={classes.container}>
      <Title>
        <span>Awards</span> and <span>Recognition</span>
      </Title>
      <Marquee>
        <Col className={classes.desktopAwardList}>
          {flatten(Array(100).fill(awards)).map((award) => {
            return (
              <Col key={award.id}>
                <Col>
                  <img src={award.logo} />
                </Col>
                <p>{award.title}</p>
              </Col>
            );
          })}
        </Col>
      </Marquee>
    </Col>
  );
};

export default Awards;
