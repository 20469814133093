export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.substring(1);
};

export const sortNumericalArrayAscend = (array) => {
  array.sort((a, b) => a - b);
};

export const sortNumericalArrayDescend = (array) => {
  array.sort((a, b) => b - a);
};

export const convertArrayToHashMap = (arrayInput = [], mapBy = "id") => {
  const object = {};
  arrayInput.forEach((item) => {
    object[item[mapBy]] = item;
  });
  return object;
};

export const convertHashMapToArray = (objectInput) => {
  const array = Object.values(objectInput);
  return array;
};
