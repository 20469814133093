// extracted by mini-css-extract-plugin
export var courseImage = "SearchCourses-module--courseImage--c9fb0";
export var courseImageContainer = "SearchCourses-module--courseImageContainer--30bd0";
export var courseResultListContainer = "SearchCourses-module--courseResultListContainer--9cf06";
export var courseResultListitem = "SearchCourses-module--courseResultListitem--9edb8";
export var courseTitle = "SearchCourses-module--courseTitle--ecaff";
export var lmsScrollbar = "SearchCourses-module--lms-scrollbar--3a8c7";
export var searchCourses = "SearchCourses-module--searchCourses--7c4ce";
export var searchCoursesInputContainer = "SearchCourses-module--searchCoursesInputContainer--2228d";
export var searchIcon = "SearchCourses-module--searchIcon--68e01";
export var searchResultLable = "SearchCourses-module--searchResultLable--2dc5d";