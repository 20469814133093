import { Col, Typography } from "antd";
import React from "react";
import { jobCompanies } from "../../../utils/constants";

import * as classes from "./LearningAt.module.scss";
import { flatten } from "lodash";

const { Title } = Typography;

const Marquee =
  typeof window !== `undefined`
    ? require("react-simple-marquee").default
    : null;

const LearningAt = () => {
  return (
    <Col className={classes.container}>
      <Title>
        Our <span>Unschoolers</span> are interning At
      </Title>
      <Col>
        {typeof window !== "undefined" && (
          <Marquee>
            {flatten(Array(100).fill(jobCompanies)).map((company, index) => (
              <img
                key={index}
                src={company.logoUrl}
                className={classes.logo}
                alt={company.name}
              />
            ))}
          </Marquee>
        )}
      </Col>
    </Col>
  );
};

export default LearningAt;
