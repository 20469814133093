import React, { useEffect, useState } from "react";
import { Col, Select, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";

import * as classes from "./Hero.module.scss";
import { CustomButton } from "../../adaptors/CustomButton";
import { variants } from "../../../utils/variants";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_COURSES } from "../../../../graphql/queries/courses";
import { CREATE_INTERNSHIP_LEAD } from "../../../../graphql/mutations/lead";
import { phoneNumberValidator } from "../../../utils/helpers/validations";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { helperActions } from "../../../utils/redux/actions";
import classNames from "classnames";

const ProgramForm = () => {
  const [form] = useForm();
  const [courses, setCourses] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [saveLeadMutaion] = useMutation(CREATE_INTERNSHIP_LEAD);
  const [getCourses] = useLazyQuery(GET_COURSES, {
    variables: {
      input: {
        productLine: "internship_program",
      },
    },
    onCompleted: (value) => {
      const tempCourses = value.courses.data.map((course) => ({
        ...course,
        label: course.title,
        value: course.id,
      }));
      setCourses(tempCourses);
    },
  });

  const dispatch = useDispatch();

  const { openToastMessage } = bindActionCreators(helperActions, dispatch);

  const onFinish = (values) => {
    setButtonLoading(true);
    try {
      saveLeadMutaion({
        variables: { ...values },
      });
      setFormSubmitted(true);
      setButtonLoading(false);
    } catch (error) {
      const gqlError = error.graphQLErrors[0];
      if (gqlError) openToastMessage({ variant: variants.error });
      setButtonLoading(false);
    }
  };

  useEffect(() => {
    getCourses();
  }, []);

  return (
    <Col className={classes.programForm}>
      <h1>Get NSDC Program Details </h1>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={formSubmitted ? () => {} : onFinish}
        autoComplete="off"
        layout="vertical"
        className={classes.form}
        form={form}
      >
        <Form.Item
          label="Name"
          name="fullName"
          rules={[{ required: true, message: "Name is required!" }]}
          className={classes.formItem}
        >
          <Input placeholder="Enter your name" type="text" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please provide valid email!",
            },
          ]}
          className={classes.formItem}
        >
          <Input placeholder="Enter your email id" />
        </Form.Item>
        <Form.Item
          label="Phone Number"
          name="phoneNumber"
          rules={[
            {
              required: true,
              type: "number",
              message: "Please provide valid phone number!",
              validator: phoneNumberValidator,
            },
          ]}
          className={classes.formItem}
        >
          <Input placeholder="Enter your number" type="number" />
        </Form.Item>
        <Form.Item
          label="Choose Course"
          name="courseId"
          rules={[{ required: true, message: "Please select course!" }]}
          className={classes.formItem}
        >
          <Select options={courses} placeholder="Select Course" />
        </Form.Item>
        <Form.Item>
          <CustomButton
            title={formSubmitted ? "Submitted" : "Download Brochure"}
            variant={variants.primaryButton}
            fitContent={true}
            customClass={classNames(classes.customButtonClass)}
            customTextClass={classNames(
              classes.customButtonTextClass,
              formSubmitted && classes.formSubmittedButtonText
            )}
            htmlType="submit"
            loading={buttonLoading}
          />
        </Form.Item>
        {formSubmitted && (
          <p className={classes.formSubmissionSuccessText}>
            Thank you <span>for your interest in this course</span>, our team
            will get back to you
          </p>
        )}
      </Form>
    </Col>
  );
};

export default ProgramForm;
