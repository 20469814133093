import React from "react";
import { Col, Typography } from "antd";

import * as classes from "./Stats.module.scss";
import { stats } from "../Utils/data";

const { Title } = Typography;

const Stats = () => {
  return (
    <Col className={classes.container}>
      <Col className={classes.wrapper}>
        {stats.map((stat) => {
          return (
            <Col key={stat.id}>
              <Title>{stat.value}</Title>
              <p>{stat.title}</p>
            </Col>
          );
        })}
      </Col>
    </Col>
  );
};

export default Stats;
