import React, { useState } from "react";
import { Typography, Col } from "antd";
import Categories from "../Categories";

import * as classes from "./Course.module.scss";
import CoursesCarouselDesktop from "./CoursesCarouselDesktop";
import CoursesCarouselMobile from "./CoursesCarouselMobile";
import SearchCourses from "../SearchCourses";
import { useCategoryCourseList } from "../hooks/useCategoryCourseList";
import {
  convertArrayToHashMap,
  convertHashMapToArray,
} from "../../../utils/functions";
import { useEffect } from "react";
import { isEmpty } from "lodash";
import { categories } from "../Utils/data";

const { Title } = Typography;

const Courses = (props) => {
  const { categoryCourseList } = props;

  const courses = convertArrayToHashMap(categoryCourseList.data, "slug");
  const [categoryCorouselList, setCategoryCorouselList] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("design");

  useEffect(() => {
    const modifiedCategoriesList = {};
    convertHashMapToArray(categories).forEach((item) => {
      if (item.slug === "vocational") {
        const filterArtAndHumanitiesCourses = courses[
          "art-and-humanities"
        ]?.courses?.nodes.filter(
          (item) =>
            item.productLine === "internship_program" &&
            item.visibleOnProductPage
        );
        const filterPersonalDevelopmentCourses = courses[
          "personal-development"
        ]?.courses?.nodes.filter(
          (item) =>
            item.productLine === "internship_program" &&
            item.visibleOnProductPage
        );
        return (modifiedCategoriesList["vocational"] = {
          ...item,
          courses: [
            ...filterArtAndHumanitiesCourses,
            ...filterPersonalDevelopmentCourses,
          ],
        });
      }
      if (item.slug === "all-categories") {
        return (modifiedCategoriesList["all-categories"] = {
          ...item,
          courses: [],
        });
      } else {
        const filterArtAndHumanitiesCourses = courses[
          item.slug
        ]?.courses?.nodes.filter(
          (item) =>
            item.productLine === "internship_program" &&
            item.visibleOnProductPage
        );
        return (modifiedCategoriesList[item.slug] = {
          ...item,
          courses: filterArtAndHumanitiesCourses,
        });
      }
    });
    setCategoryCorouselList(modifiedCategoriesList);
  }, []);

  return (
    <Col className={classes.container}>
      <Col className={classes.wrapper}>
        <Title className={classes.title}>
          A broad selection of
          <span>Programs, Courses & Skills</span>
        </Title>
        <Col className={classes.coursesContentDesktop}>
          <Col className={classes.categories}>
            <Categories
              categories={convertHashMapToArray(categories)}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
          </Col>
          <Col className={classes.courseCarousel}>
            <Col className={classes.searchContainer}>
              <h3>{categories[selectedCategory]?.title}</h3>
              <SearchCourses />
            </Col>
            {!isEmpty(categoryCorouselList) && (
              <CoursesCarouselDesktop
                courseList={categoryCorouselList[selectedCategory].courses}
              />
            )}
          </Col>
        </Col>
        <Col className={classes.coursesContentMobile}>
          <CoursesCarouselMobile
            categories={convertHashMapToArray(categories)}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            courseList={
              !isEmpty(categoryCorouselList)
                ? categoryCorouselList[selectedCategory].courses
                : []
            }
          />
        </Col>
      </Col>
    </Col>
  );
};

export default Courses;
